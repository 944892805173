<template>
	<div id="app">
		<main class="container">
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">4</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<span class="particle">0</span>
			<article class="content">
				<p>{{ web.domain }}</p>
				<p>
					<a href="https://beian.miit.gov.cn/" target="_blank">{{
						web.icp
					}}</a>
				</p>
				<p>
					<button>{{ web.url }}</button>
				</p>
			</article>
		</main>
	</div>
</template>
<script>
export default {
	data() {
		return {
			web: {
				domain: "石门网",
				icp: "湘ICP备20002616号",
				url: "www.shimen.cn",
			},
		};
	},
	mounted() {
		setTimeout(() => {
			this.url();
		}, 100);
	},
	methods: {
		url() {
			const url = document.domain;
			switch (url) {
				case "shimen.cn" || "www.shimen.cn":
					document.title = "石门网";
					this.web = {
						domain: "石门网",
						icp: "湘ICP备20002616号-1",
					};
					break;
				case "shimen.biz" || "www.shimen.biz":
					document.title = "石门梦想家";
					this.web = {
						domain: "石门梦想家",
						icp: "湘ICP备20002616号-5",
					};
					break;
				case "vrcc.cn" || "www.vrcc.cn":
					document.title = "全景石门";
					this.web = {
						domain: "全景石门",
						icp: "湘ICP备20002616号-6",
					};
					break;
				case "ganjujie.cn" || "www.ganjujie.cn":
					document.title = "柑橘节";
					this.web = {
						domain: "柑橘节",
						icp: "湘ICP备20002616号-4",
					};
					break;
				case "wsm.hn.cn" || "www.wsm.hn.cn":
					document.title = "微石门";
					this.web = {
						domain: "微石门",
						icp: "湘ICP备20002616号-2",
					};
					break;
				default:
					document.title = "石门网";
			}
			this.web.url = url;
		},
	},
};
</script>
<style lang="scss">
body {
	margin: 0;
	font-size: 20px;
}

* {
	box-sizing: border-box;
}

.container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background: white;
	color: black;
	font-family: arial, sans-serif;
	overflow: hidden;
}

.content {
	position: relative;
	width: 600px;
	max-width: 100%;
	margin: 20px;
	background: white;
	padding: 60px 40px;
	text-align: center;
	opacity: 0;
	border-radius: 28px;
	animation: apparition 0.8s 1.2s cubic-bezier(0.39, 0.575, 0.28, 0.995)
		forwards;
	backdrop-filter: saturate(180%) blur(28px);
	background: rgba(255, 255, 255, 0.01);
}
.content p {
	font-size: 1.3rem;
	margin-top: 0;
	margin-bottom: 0;
	letter-spacing: 0.1rem;
	color: #595959;
}
.content p a {
	text-decoration: none;
	font-size: 14px;
}
.content p:last-child {
	margin-bottom: 0;
}
.content button {
	display: inline-block;
	margin-top: 2rem;
	padding: 0.5rem 1rem;
	border: 3px solid #595959;
	background: transparent;
	font-size: 1rem;
	color: #595959;
	text-decoration: none;
	cursor: pointer;
	font-weight: bold;
}

.particle {
	position: absolute;
	display: block;
	pointer-events: none;
}
.particle:nth-child(1) {
	top: 65.0246305419%;
	left: 47.4308300395%;
	font-size: 12px;
	filter: blur(0.02px);
	animation: 32s float2 infinite;
}
.particle:nth-child(2) {
	top: 83.4355828221%;
	left: 18.7192118227%;
	font-size: 15px;
	filter: blur(0.04px);
	animation: 31s float2 infinite;
}
.particle:nth-child(3) {
	top: 69.0505548705%;
	left: 67.2601384768%;
	font-size: 11px;
	filter: blur(0.06px);
	animation: 40s floatReverse2 infinite;
}
.particle:nth-child(4) {
	top: 16.3855421687%;
	left: 15.5339805825%;
	font-size: 30px;
	filter: blur(0.08px);
	animation: 27s float infinite;
}
.particle:nth-child(5) {
	top: 31.0303030303%;
	left: 32.1951219512%;
	font-size: 25px;
	filter: blur(0.1px);
	animation: 26s float infinite;
}
.particle:nth-child(6) {
	top: 85.8199753391%;
	left: 9.8911968348%;
	font-size: 11px;
	filter: blur(0.12px);
	animation: 32s floatReverse infinite;
}
.particle:nth-child(7) {
	top: 72.1951219512%;
	left: 52.9411764706%;
	font-size: 20px;
	filter: blur(0.14px);
	animation: 39s float2 infinite;
}
.particle:nth-child(8) {
	top: 44.0097799511%;
	left: 78.5854616896%;
	font-size: 18px;
	filter: blur(0.16px);
	animation: 25s floatReverse2 infinite;
}
.particle:nth-child(9) {
	top: 44.7688564477%;
	left: 77.2994129159%;
	font-size: 22px;
	filter: blur(0.18px);
	animation: 39s floatReverse2 infinite;
}
.particle:nth-child(10) {
	top: 52.2167487685%;
	left: 57.3122529644%;
	font-size: 12px;
	filter: blur(0.2px);
	animation: 39s float infinite;
}
.particle:nth-child(11) {
	top: 36.053593179%;
	left: 30.3623898139%;
	font-size: 21px;
	filter: blur(0.22px);
	animation: 27s float infinite;
}
.particle:nth-child(12) {
	top: 86.3803680982%;
	left: 71.921182266%;
	font-size: 15px;
	filter: blur(0.24px);
	animation: 24s floatReverse infinite;
}
.particle:nth-child(13) {
	top: 38.4710234279%;
	left: 23.7388724036%;
	font-size: 11px;
	filter: blur(0.26px);
	animation: 28s floatReverse infinite;
}
.particle:nth-child(14) {
	top: 49.6350364964%;
	left: 6.8493150685%;
	font-size: 22px;
	filter: blur(0.28px);
	animation: 35s float infinite;
}
.particle:nth-child(15) {
	top: 54.501216545%;
	left: 13.698630137%;
	font-size: 22px;
	filter: blur(0.3px);
	animation: 25s float infinite;
}
.particle:nth-child(16) {
	top: 52.2370012092%;
	left: 63.2911392405%;
	font-size: 27px;
	filter: blur(0.32px);
	animation: 37s float infinite;
}
.particle:nth-child(17) {
	top: 44.9877750611%;
	left: 39.2927308448%;
	font-size: 18px;
	filter: blur(0.34px);
	animation: 36s floatReverse2 infinite;
}
.particle:nth-child(18) {
	top: 40.2948402948%;
	left: 56.2130177515%;
	font-size: 14px;
	filter: blur(0.36px);
	animation: 31s float infinite;
}
.particle:nth-child(19) {
	top: 25.6157635468%;
	left: 39.5256916996%;
	font-size: 12px;
	filter: blur(0.38px);
	animation: 30s floatReverse infinite;
}
.particle:nth-child(20) {
	top: 26.6009852217%;
	left: 87.9446640316%;
	font-size: 12px;
	filter: blur(0.4px);
	animation: 22s floatReverse2 infinite;
}
.particle:nth-child(21) {
	top: 67.0776818742%;
	left: 25.7171117705%;
	font-size: 11px;
	filter: blur(0.42px);
	animation: 36s float infinite;
}
.particle:nth-child(22) {
	top: 83.4355828221%;
	left: 54.1871921182%;
	font-size: 15px;
	filter: blur(0.44px);
	animation: 30s floatReverse infinite;
}
.particle:nth-child(23) {
	top: 91.1764705882%;
	left: 92.5196850394%;
	font-size: 16px;
	filter: blur(0.46px);
	animation: 38s float infinite;
}
.particle:nth-child(24) {
	top: 46.1916461916%;
	left: 72.9783037475%;
	font-size: 14px;
	filter: blur(0.48px);
	animation: 25s float infinite;
}
.particle:nth-child(25) {
	top: 43.956043956%;
	left: 83.4151128557%;
	font-size: 19px;
	filter: blur(0.5px);
	animation: 22s float2 infinite;
}
.particle:nth-child(26) {
	top: 12.6674786845%;
	left: 94.0254652302%;
	font-size: 21px;
	filter: blur(0.52px);
	animation: 40s floatReverse infinite;
}
.particle:nth-child(27) {
	top: 11.6222760291%;
	left: 14.6198830409%;
	font-size: 26px;
	filter: blur(0.54px);
	animation: 33s float2 infinite;
}
.particle:nth-child(28) {
	top: 67.7300613497%;
	left: 6.8965517241%;
	font-size: 15px;
	filter: blur(0.56px);
	animation: 26s float infinite;
}
.particle:nth-child(29) {
	top: 90.0726392252%;
	left: 21.4424951267%;
	font-size: 26px;
	filter: blur(0.58px);
	animation: 26s float2 infinite;
}
.particle:nth-child(30) {
	top: 91.6256157635%;
	left: 83.9920948617%;
	font-size: 12px;
	filter: blur(0.6px);
	animation: 27s float infinite;
}
.particle:nth-child(31) {
	top: 28.2238442822%;
	left: 52.8375733855%;
	font-size: 22px;
	filter: blur(0.62px);
	animation: 25s floatReverse2 infinite;
}
.particle:nth-child(32) {
	top: 13.6585365854%;
	left: 1.9607843137%;
	font-size: 20px;
	filter: blur(0.64px);
	animation: 30s float infinite;
}
.particle:nth-child(33) {
	top: 98.4009840098%;
	left: 76.9990128332%;
	font-size: 13px;
	filter: blur(0.66px);
	animation: 30s float2 infinite;
}
.particle:nth-child(34) {
	top: 40.6287787183%;
	left: 96.3972736125%;
	font-size: 27px;
	filter: blur(0.68px);
	animation: 33s float infinite;
}
.particle:nth-child(35) {
	top: 49.57472661%;
	left: 56.6959921799%;
	font-size: 23px;
	filter: blur(0.7px);
	animation: 36s float2 infinite;
}
.particle:nth-child(36) {
	top: 83.2313341493%;
	left: 84.5624385447%;
	font-size: 17px;
	filter: blur(0.72px);
	animation: 25s float infinite;
}
.particle:nth-child(37) {
	top: 68.4337349398%;
	left: 10.6796116505%;
	font-size: 30px;
	filter: blur(0.74px);
	animation: 32s float infinite;
}
.particle:nth-child(38) {
	top: 5.8968058968%;
	left: 67.0611439842%;
	font-size: 14px;
	filter: blur(0.76px);
	animation: 26s floatReverse infinite;
}
.particle:nth-child(39) {
	top: 0.981595092%;
	left: 97.5369458128%;
	font-size: 15px;
	filter: blur(0.78px);
	animation: 34s floatReverse infinite;
}
.particle:nth-child(40) {
	top: 1.9607843137%;
	left: 33.4645669291%;
	font-size: 16px;
	filter: blur(0.8px);
	animation: 29s float infinite;
}
.particle:nth-child(41) {
	top: 10.7055961071%;
	left: 9.7847358121%;
	font-size: 22px;
	filter: blur(0.82px);
	animation: 39s floatReverse infinite;
}
.particle:nth-child(42) {
	top: 8.6851628468%;
	left: 68.0272108844%;
	font-size: 29px;
	filter: blur(0.84px);
	animation: 28s floatReverse infinite;
}
.particle:nth-child(43) {
	top: 12.6060606061%;
	left: 89.756097561%;
	font-size: 25px;
	filter: blur(0.86px);
	animation: 26s float infinite;
}
.particle:nth-child(44) {
	top: 13.7254901961%;
	left: 13.7795275591%;
	font-size: 16px;
	filter: blur(0.88px);
	animation: 33s floatReverse infinite;
}
.particle:nth-child(45) {
	top: 50.6699147381%;
	left: 61.7042115573%;
	font-size: 21px;
	filter: blur(0.9px);
	animation: 26s floatReverse infinite;
}
.particle:nth-child(46) {
	top: 92.9782082324%;
	left: 72.1247563353%;
	font-size: 26px;
	filter: blur(0.92px);
	animation: 26s float2 infinite;
}
.particle:nth-child(47) {
	top: 94.2331288344%;
	left: 25.6157635468%;
	font-size: 15px;
	filter: blur(0.94px);
	animation: 21s floatReverse infinite;
}
.particle:nth-child(48) {
	top: 33.734939759%;
	left: 86.4077669903%;
	font-size: 30px;
	filter: blur(0.96px);
	animation: 32s floatReverse2 infinite;
}
.particle:nth-child(49) {
	top: 16.4449818622%;
	left: 18.5004868549%;
	font-size: 27px;
	filter: blur(0.98px);
	animation: 40s floatReverse2 infinite;
}
.particle:nth-child(50) {
	top: 30.1703163017%;
	left: 55.7729941292%;
	font-size: 22px;
	filter: blur(1px);
	animation: 26s floatReverse infinite;
}
.particle:nth-child(51) {
	top: 19.728729963%;
	left: 44.5103857567%;
	font-size: 11px;
	filter: blur(1.02px);
	animation: 31s float2 infinite;
}
.particle:nth-child(52) {
	top: 25.3349573691%;
	left: 74.4368266405%;
	font-size: 21px;
	filter: blur(1.04px);
	animation: 21s float infinite;
}
.particle:nth-child(53) {
	top: 89.6551724138%;
	left: 55.3359683794%;
	font-size: 12px;
	filter: blur(1.06px);
	animation: 38s floatReverse infinite;
}
.particle:nth-child(54) {
	top: 10.7055961071%;
	left: 34.2465753425%;
	font-size: 22px;
	filter: blur(1.08px);
	animation: 31s float infinite;
}
.particle:nth-child(55) {
	top: 94.4578313253%;
	left: 81.5533980583%;
	font-size: 30px;
	filter: blur(1.1px);
	animation: 25s floatReverse infinite;
}
.particle:nth-child(56) {
	top: 14.7239263804%;
	left: 12.8078817734%;
	font-size: 15px;
	filter: blur(1.12px);
	animation: 31s float infinite;
}
.particle:nth-child(57) {
	top: 16.52490887%;
	left: 22.4828934506%;
	font-size: 23px;
	filter: blur(1.14px);
	animation: 34s floatReverse infinite;
}
.particle:nth-child(58) {
	top: 70.1583434836%;
	left: 91.0871694417%;
	font-size: 21px;
	filter: blur(1.16px);
	animation: 25s float2 infinite;
}
.particle:nth-child(59) {
	top: 61.8404907975%;
	left: 7.881773399%;
	font-size: 15px;
	filter: blur(1.18px);
	animation: 25s floatReverse2 infinite;
}
.particle:nth-child(60) {
	top: 30.0606060606%;
	left: 48.7804878049%;
	font-size: 25px;
	filter: blur(1.2px);
	animation: 24s float2 infinite;
}
.particle:nth-child(61) {
	top: 7.8048780488%;
	left: 94.1176470588%;
	font-size: 20px;
	filter: blur(1.22px);
	animation: 32s float infinite;
}
.particle:nth-child(62) {
	top: 51.7073170732%;
	left: 51.9607843137%;
	font-size: 20px;
	filter: blur(1.24px);
	animation: 25s float2 infinite;
}
.particle:nth-child(63) {
	top: 70.3614457831%;
	left: 19.4174757282%;
	font-size: 30px;
	filter: blur(1.26px);
	animation: 25s float2 infinite;
}
.particle:nth-child(64) {
	top: 35.9223300971%;
	left: 72.265625%;
	font-size: 24px;
	filter: blur(1.28px);
	animation: 38s float infinite;
}
.particle:nth-child(65) {
	top: 15.6097560976%;
	left: 84.3137254902%;
	font-size: 20px;
	filter: blur(1.3px);
	animation: 28s float2 infinite;
}
.particle:nth-child(66) {
	top: 94.6979038224%;
	left: 33.6300692384%;
	font-size: 11px;
	filter: blur(1.32px);
	animation: 33s floatReverse infinite;
}
.particle:nth-child(67) {
	top: 12.7921279213%;
	left: 36.5251727542%;
	font-size: 13px;
	filter: blur(1.34px);
	animation: 32s float infinite;
}
.particle:nth-child(68) {
	top: 50.3631961259%;
	left: 82.8460038986%;
	font-size: 26px;
	filter: blur(1.36px);
	animation: 23s floatReverse2 infinite;
}
.particle:nth-child(69) {
	top: 96.3855421687%;
	left: 45.6310679612%;
	font-size: 30px;
	filter: blur(1.38px);
	animation: 39s float2 infinite;
}
.particle:nth-child(70) {
	top: 57.3511543135%;
	left: 52.7859237537%;
	font-size: 23px;
	filter: blur(1.4px);
	animation: 34s float infinite;
}
.particle:nth-child(71) {
	top: 32.1167883212%;
	left: 59.686888454%;
	font-size: 22px;
	filter: blur(1.42px);
	animation: 22s float2 infinite;
}
.particle:nth-child(72) {
	top: 51.7704517705%;
	left: 78.5083415113%;
	font-size: 19px;
	filter: blur(1.44px);
	animation: 37s float2 infinite;
}
.particle:nth-child(73) {
	top: 84.4171779141%;
	left: 11.8226600985%;
	font-size: 15px;
	filter: blur(1.46px);
	animation: 29s floatReverse2 infinite;
}
.particle:nth-child(74) {
	top: 10.615199035%;
	left: 37.9008746356%;
	font-size: 29px;
	filter: blur(1.48px);
	animation: 25s floatReverse infinite;
}
.particle:nth-child(75) {
	top: 71.1327649208%;
	left: 37.2184133203%;
	font-size: 21px;
	filter: blur(1.5px);
	animation: 38s floatReverse infinite;
}
.particle:nth-child(76) {
	top: 68.9655172414%;
	left: 2.9644268775%;
	font-size: 12px;
	filter: blur(1.52px);
	animation: 30s float2 infinite;
}
.particle:nth-child(77) {
	top: 64.8125755744%;
	left: 55.5014605648%;
	font-size: 27px;
	filter: blur(1.54px);
	animation: 32s floatReverse infinite;
}
.particle:nth-child(78) {
	top: 55.9036144578%;
	left: 36.8932038835%;
	font-size: 30px;
	filter: blur(1.56px);
	animation: 28s floatReverse infinite;
}
.particle:nth-child(79) {
	top: 24.154589372%;
	left: 95.3307392996%;
	font-size: 28px;
	filter: blur(1.58px);
	animation: 28s float2 infinite;
}
.particle:nth-child(80) {
	top: 2.9339853301%;
	left: 79.5677799607%;
	font-size: 18px;
	filter: blur(1.6px);
	animation: 31s float2 infinite;
}

@keyframes apparition {
	from {
		opacity: 0;
		transform: translateY(100px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes float {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(180px);
	}
}
@keyframes floatReverse {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-180px);
	}
}
@keyframes float2 {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(28px);
	}
}
@keyframes floatReverse2 {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-28px);
	}
}
</style>
